'use strict';

import goodSources from '../tables/good-sources';
import allNutrients from '../tables/nutrients';

import UserStore from '../stores/UserStore';

const searchableTypes = ['recipe', 'plan', 'combo', 'food', 'collection', 'category', 'brand'];

export function getParamsFromQuery(query, profile, types = ['recipe'], size = 12) {
    if (!profile) {
        profile = UserStore.getUser();
    }

    const { preferences: { recommended_mode_source }, features } = profile;

    let params = {
        terms: '',
        types: types.slice(0),
        filters: {
            'library': false,
            'tags': [],
            '!tags': [],
        },
        from: 0,
        size,
        sort_by: 'published',
        sort_params: {},
        include_merchants: features?.source_libraries || null
    };

    if (query.lang) {
        params.language = query.lang;
    }

    if (query.sort) {
        params.sort_by = query.sort;
    }

    if (query.sizes) {
        // const maxLeftoverDays = (profile && profile.preferences && profile.preferences.max_leftover_days) || 1;
        // const participants = 1 + (profile && profile.family && profile.family.length);

        params.filters.sizes = query.sizes;
    }

    if (query.servingsMin) {
        params.filters.servings = params.filters.servings || {};
        params.filters.servings.gte = query.servingsMin;
    }

    if (query.servingsMax) {
        params.filters.servings = params.filters.servings || {};
        params.filters.servings.lte = query.servingsMax;
    }

    if (query.unpublished) {
        params.admin_list = true;
    }

    if (['asc', 'desc'].includes(query.order)) {
        params.sort_order = query.order;
    }

    if (query.terms) {
        params.terms = query.terms;
    }

    if (query.brandUuid) {
        params.filters.brand_uuid = query.brandUuid;
    }

    if (query.types) {
        if (typeof query.types === 'string') {
            params.types = [query.types];
        } else if (query.types.constructor === Array) {
            params.types = query.types.filter(type => typeof type === 'string' && searchableTypes.includes(type));
        }
    }

    if (query.tags) {
        // Only append strings to the tags array
        if (typeof query.tags === 'string') {
            params.filters.tags.push(query.tags);
        } else if (query.tags.constructor === Array) {
            params.filters.tags = params.filters.tags.concat(query.tags.filter(t => typeof t === 'string'));
        }
    }

    if (query.excludes) {
        if (typeof query.excludes === 'string') {
            params.filters['!tags'].push(query.excludes);
        } else if (query.excludes.constructor === Array) {
            params.filters['!tags'] = params.filters['!tags'].concat(query.excludes.filter(t => typeof t === 'string'));
        }
    }

    if (query.exUuid) {
        params.filters['!uuid'] = params.filters['!uuid'] || [];
        if (typeof query.exUuid === 'string') {
            params.filters['!uuid'].push(query.exUuid);
        } else if (query.exUuid.constructor === Array) {
            params.filters['!uuid'] = params.filters['!uuid'].concat(query.exUuid.filter(t => typeof t === 'string'));
        }
    }

    if (query.exMain) {
        params.filters['!main_dish'] = params.filters['!main_dish'] || [];
        if (typeof query.exMain === 'string') {
            params.filters['!main_dish'].push(query.exMain);
        } else if (query.exMain.constructor === Array) {
            params.filters['!main_dish'] = params.filters['!main_dish'].concat(query.exMain.filter(t => typeof t === 'string'));
        }
    }

    if (query.exSide) {
        params.filters['!side_dish'] = params.filters['!side_dish'] || [];
        if (typeof query.exSide === 'string') {
            params.filters['!side_dish'].push(query.exSide);
        } else if (query.exSide.constructor === Array) {
            params.filters['!side_dish'] = params.filters['!side_dish'].concat(query.exSide.filter(t => typeof t === 'string'));
        }
    }

    if (query.main) {
        params.filters['main_dish'] = params.filters['main_dish'] || [];
        if (typeof query.main === 'string') {
            params.filters['main_dish'].push(query.main);
        }
    }

    if (query.side) {
        params.filters['side_dish'] = params.filters['side_dish'] || [];
        if (typeof query.side === 'string') {
            params.filters['side_dish'].push(query.side);
        }
    }

    if (query.exFood) {
        params.filters['!foods'] = params.filters['!foods'] || [];
        if (typeof query.exFood === 'string') {
            params.filters['!foods'].push(query.exFood);
        } else if (query.exFood.constructor === Array) {
            params.filters['!foods'] = params.filters['!foods'].concat(query.exFood.filter(t => typeof t === 'string'));
        }
    }

    if (query.foods) {
        params.filters['foods'] = params.filters['foods'] || [];
        if (typeof query.foods === 'string') {
            params.filters.foods.push(query.foods);
        } else if (query.foods.constructor === Array) {
            params.filters.foods = params.filters.foods.concat(query.foods.filter(t => typeof t === 'string'));
        }
    }

    if (query.exTerms) {
        params.filters['!terms'] = query.exTerms;
    }

    if (query.category) {
        params.filters.category = query.category;
    }

    if (query.library) {
        params.filters.library = true;
    }

    if (query.include_library) {
        params.include_library = true;
    }

    if (query.protection) {
        params.filters.protection = query.protection;
    }

    if (query.productType) {
        params.filters.product_type = query.productType;
    }

    if (query.isOwner) {
        params.filters.is_owner = Boolean(query.isOwner);
    }

    if (query.pageType) {
        params.filters.page_type = query.pageType;
    }

    if (query.totalTimeMin) {
        params.filters.total_time = params.filters.total_time || {};
        params.filters.total_time.gte = parseInt(query.totalTimeMin);
    }

    if (query.totalTimeMax) {
        params.filters.total_time = params.filters.total_time || {};
        params.filters.total_time.lte = parseInt(query.totalTimeMax);
    }

    if (query.freshMin) {
        params.filters.total_fresh = params.filters.total_fresh || {};
        params.filters.total_fresh.gte = parseInt(query.freshMin);
    }

    if (query.freshMax) {
        params.filters.total_fresh = params.filters.total_fresh || {};
        params.filters.total_fresh.lte = parseInt(query.freshMax);
    }

    if (query.ingredientsMin) {
        params.filters.total_ingredients = params.filters.total_ingredients || {};
        params.filters.total_ingredients.gte = parseInt(query.ingredientsMin) || 0;
    }

    if (query.ingredientsMax) {
        params.filters.total_ingredients = params.filters.total_ingredients || {};
        params.filters.total_ingredients.lte = parseInt(query.ingredientsMax) || 0;
    }

    if (query.costMin) {
        params.filters.avg_cost_per_serving = params.filters.avg_cost_per_serving || {};
        params.filters.avg_cost_per_serving.gte = parseInt(query.costMin);
    }

    if (query.costMax) {
        params.filters.avg_cost_per_serving = params.filters.avg_cost_per_serving || {};
        params.filters.avg_cost_per_serving.lte = parseInt(query.costMax);
    }

    if (query.costMin) {
        params.filters.avg_cost_per_serving = params.filters.avg_cost_per_serving || {};
        params.filters.avg_cost_per_serving.gte = parseInt(query.costMin);
    }

    if (query.costMax) {
        params.filters.avg_cost_per_serving = params.filters.avg_cost_per_serving || {};
        params.filters.avg_cost_per_serving.lte = parseInt(query.costMax);
    }

    if (query.refuseMin) {
        params.filters.refuse = params.filters.refuse || {};
        params.filters.refuse.gte = parseInt(query.refuseMin);
    }

    if (query.refuseMax) {
        params.filters.refuse = params.filters.refuse || {};
        params.filters.refuse.lte = parseInt(query.refuseMax);
    }

    if (query.prepMax) {
        params.filters.hands_on_time = params.filters.hands_on_time || {};
        params.filters.hands_on_time.lte = parseInt(query.prepMax);
    }

    if (query.prepMin) {
        params.filters.hands_on_time = params.filters.hands_on_time || {};
        params.filters.hands_on_time.gte = parseInt(query.prepMin);
    }

    Object.keys(allNutrients).forEach(nutrNo => {
        const nutrient = allNutrients[nutrNo];

        if (!nutrient.Filter) {
            return;
        }

        const minKey = nutrient.Filter + 'Min';
        const maxKey = nutrient.Filter + 'Max';
        const shouldHaveValueKey = nutrient.Filter + 'ShouldHaveValue';

        const range = {};

        if (typeof query[minKey] !== 'undefined') {
            range.gte = parseFloat(query[minKey]) || 0;
        }

        if (typeof query[maxKey] !== 'undefined') {
            range.lte = parseFloat(query[maxKey]) || 0;
        }

        if (typeof query[shouldHaveValueKey] !== 'undefined') {
            range.shouldHaveValue = query[shouldHaveValueKey];
        }

        if (Object.keys(range).length > 0) {
            params.filters[nutrient.Filter] = range;
        }
    });

    if (query.prescription && profile) {
        params.filters.prescriptions = profile.prescriptions;
    }

    if (query.strict_rx && profile) {
        params.filters.strict_rx = profile.prescriptions;
    }

    if (typeof query.breakfasts !== 'undefined') {
        params.filters.breakfasts = {gte: parseInt(query.breakfasts), lte: parseInt(query.breakfasts)};
    }

    if (typeof query.lunches !== 'undefined') {
        params.filters.lunches = {gte: parseInt(query.lunches), lte: parseInt(query.lunches)};
    }

    if (typeof query.dinners !== 'undefined') {
        params.filters.dinners = {gte: parseInt(query.dinners), lte: parseInt(query.dinners)};
    }

    if (typeof query.snacks !== 'undefined') {
        params.filters.snacks = {gte: 0, lte: parseInt(query.snacks)};
    }

    if (query.serving && query.serving > 0) {
        params.serving = query.serving;
    }

    //if (query.goodSource) {
    //    Object.keys(goodSources).forEach(filter => {
    //        if (query.goodSource.indexOf(filter) != -1) {
    //            params.filters[filter] = {gte: goodSources[filter].v};
    //        }
    //    });
    //}

    if (query.avoids && (query.avoids.constructor === Array || typeof query.avoids == 'string')) {
        // Do we have any avoids?
        params.filters['!ingredient_tags'] = params.filters['!ingredient_tags'] || [];

        let avoids = query.avoids.constructor === Array
                   ? query.avoids
                   : [query.avoids];

        avoids.forEach(tag => {
            if (typeof tag !== 'string') {
                return;
            }

            params.filters['!ingredient_tags'].push(tag);
        });
    }

    if (query.ingredientTags && (query.ingredientTags.constructor === Array || typeof query.ingredientTags == 'string')) {
        params.filters.ingredient_tags = params.filters.ingredient_tags || [];

        let ingredientTags = query.ingredientTags.constructor === Array
                   ? query.ingredientTags
                   : [query.ingredientTags];

        ingredientTags.forEach(tag => {
            if (typeof tag !== 'string') {
                return;
            }

            params.filters.ingredient_tags.push(tag);
        });
    }

    if (query.availableOn && (query.availableOn.constructor === Array || typeof query.availableOn == 'string')) {
        params.filters.available_on = params.filters.available_on || [];

        let availableOn = query.availableOn.constructor === Array
                        ? query.availableOn
                        : [query.availableOn];

        availableOn.forEach(tag => {
            if (typeof tag !== 'string') {
                return;
            }

            params.filters.available_on.push(tag);
        });
    }


    if (query.merchantId) {
        params.filters['merchant.uuid'] = query.merchantId;
    }

    if (query.merchantName) {
        params.filters['merchant.name'] = query.merchantName;
    }

    if (query.hasNfp) {
        params.filters.has_nfp_image = true;
    }

    if (query.hasImage) {
        params.filters.has_image = true;
    }

    if (query.provider) {
        params.filters['mapped_from.provider'] = query.provider;
    }

    if (query.provider) {
        params.filters['mapped_from.provider_db'] = query.provider_db;
    }

    return params;
}

export function getQueryFromParams(params, location, precise = false) {
    let query = {
    };

    if (params.admin_list) {
        query.unpublished = 1;
    }

    if (params.language) {
        query.lang = params.language;
    }

    query.types = searchableTypes.filter(type => params.types.includes(type));

    // Certain query parameters aren't reflected in the search parameters
    // add them here anyway.
    if (location && location.query) {
        const { rid, coid, clid, pid, bpid, fid, cid, altUser, advanced, create } = location.query;

        Object.assign(query, {rid, coid, clid, pid, bpid, fid, cid, altUser, advanced});
    }

    if (params.terms) {
        query.terms = params.terms;
    }

    if (params.include_library) {
        query.include_library = 1;
    }

    let filters = params.filters || {};

    if (filters.brand_uuid) {
        query.brandUuid = filters.brand_uuid;
    }

    if (filters.category) {
        query.category = filters.category;
    }

    if (filters.prescriptions) {
        query.prescription = 1;
    }

    if (filters.strict_rx) {
        query.strict_rx = 1;
    }

    if (filters['merchant.uuid']) {
        query.merchantId = filters['merchant.uuid'];
    }

    if (filters['merchant.name']) {
        query.merchantName = filters['merchant.name'];
    }

    if (filters.tags) {
        query.tags = filters.tags;
    }

    if (filters['!tags']) {
        query.excludes = filters['!tags'];
    }

    if (filters.sizes) {
        query.sizes = filters.sizes;
    }

    if (filters.servings && !isNaN(filters.servings.gte)) {
        query.servingsMin = filters.servings.gte;
    }

    if (filters.servings && !isNaN(filters.servings.lte)) {
        query.servingsMax = filters.servings.lte;
    }

    if (filters['!uuid']) {
        query.exUuid = filters['!uuid'];
    }

    if (filters['!main_dish']) {
        query.exMain = filters['!main_dish'];
    }

    if (filters['main_dish']) {
        query.main = filters['main_dish'];
    }

    if (filters['!side_dish']) {
        query.exSide = filters['!side_dish'];
    }

    if (filters['side_dish']) {
        query.side = filters['side_dish'];
    }


    if (filters['!foods'] && filters['!foods'].length > 0) {
        query.exFood = filters['!foods'];
    }

    if (filters['foods'] && filters['foods'].length > 0) {
        query.foods = filters['foods'];
    }

    if (filters['!terms']) {
        query.exTerms = filters['!terms'];
    }

    if (filters['mapped_from.provider']) {
        query.provider = filters['mapped_from.provider'];
    }

    if (filters['mapped_from.provider']) {
        query.provider_db = filters['mapped_from.provider_db'];
    }

    if (filters.breakfasts) {
        query.breakfasts = filters.breakfasts.lte;
    }

    if (filters.lunches) {
        query.lunches = filters.lunches.lte;
    }

    if (filters.dinners) {
        query.dinners = filters.dinners.lte;
    }

    if (filters.snacks) {
        query.snacks = filters.snacks.lte;
    }

    Object.keys(allNutrients).forEach(nutrNo => {
        const nutrient = allNutrients[nutrNo];

        if (!nutrient.Filter) {
            return;
        }

        if (filters[nutrient.Filter] && !isNaN(filters[nutrient.Filter].gte)) {
            query[nutrient.Filter + 'Min'] = precise ? filters[nutrient.Filter].gte : Math.round(filters[nutrient.Filter].gte * 100) / 100;
        }

        if (filters[nutrient.Filter] && !isNaN(filters[nutrient.Filter].lte)) {
            query[nutrient.Filter + 'Max'] = precise ? filters[nutrient.Filter].lte : Math.round(filters[nutrient.Filter].lte * 100) / 100;
        }

        if (filters[nutrient.Filter] && filters[nutrient.Filter].shouldHaveValue !== undefined) {
            query[nutrient.Filter + 'ShouldHaveValue'] = filters[nutrient.Filter].shouldHaveValue;
        }
    });

    if (filters.total_time && filters.total_time.gte) {
        query.totalTimeMin = filters.total_time.gte;
    }

    if (filters.total_time && filters.total_time.lte) {
        query.totalTimeMax = filters.total_time.lte;
    }

    if (filters.hands_on_time && filters.hands_on_time.gte) {
        query.prepMin = filters.hands_on_time.gte;
    }

    if (filters.hands_on_time && filters.hands_on_time.lte) {
        query.prepMax = filters.hands_on_time.lte;
    }

    if (filters.avg_cost_per_serving && filters.avg_cost_per_serving.gte) {
        query.costMin = filters.avg_cost_per_serving.gte;
    }

    if (filters.avg_cost_per_serving && filters.avg_cost_per_serving.lte) {
        query.costMax = filters.avg_cost_per_serving.lte;
    }

    if (filters.refuse && filters.refuse.gte) {
        query.refuseMin = filters.refuse.gte;
    }

    if (filters.refuse && filters.refuse.lte) {
        query.refuseMax = filters.refuse.lte;
    }

    if (filters.total_fresh && filters.total_fresh.gte) {
        query.freshMin = filters.total_fresh.gte;
    }

    if (filters.total_fresh && filters.total_fresh.lte) {
        query.freshMax = filters.total_fresh.lte;
    }

    if (filters.total_ingredients && filters.total_ingredients.gte) {
        query.ingredientsMin = filters.total_ingredients.gte;
    }

    if (filters.total_ingredients && filters.total_ingredients.lte) {
        query.ingredientsMax = filters.total_ingredients.lte;
    }

    if (filters.library) {
        query.library = 1;
    }

    if (filters.protection) {
        query.protection = filters.protection;
    }

    if (filters.total_fresh) {
        query.freshMin = filters.total_fresh.gte;
        query.freshMax = filters.total_fresh.lte;
    }

    if (filters.product_type) {
        query.productType = filters.product_type;
    }
    if (filters.is_owner) {
        query.isOwner = filters.is_owner
    }

    if (filters.page_type) {
        query.pageType = filters.page_type;
    }

    if (filters.ingredient_tags) {
        query.ingredientTags = filters.ingredient_tags;
    }

    // Avoidances
    if (filters['!ingredient_tags']) {
        // Do not include avoidance tags that are implied by the diets selected
        query.avoids = filters['!ingredient_tags'];
    }

    if (filters['available_on']) {
        // Do not include avoidance tags that are implied by the diets selected
        query.availableOn = filters['available_on'];
    }

    if (filters.has_image) {
        query.hasImage = 1;
    }

    if (filters.has_nfp_image) {
        query.hasNfp = 1;
    }

    if (params.sort_by && params.sort_by != 'published') {
        query.sort = params.sort_by;
    }

    if (params.sort_order) {
        query.order = params.sort_order;
    }

    return query;
}
