'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import $ from 'jquery';
import uuid from 'uuid';
import debounce from 'lodash.debounce';
import * as Sentry from '@sentry/react';

import AddSwapRecipe from '../Search/Modals/AddSwapRecipe.react';

import BoardPickerModal from '../Widgets/Boards/BoardPickerModal.react';
import MealRescheduleModal from '../Planner/Modals/MealRescheduleModal.react';
import MealRepeatModal from '../Planner/Modals/MealRepeatModal.react';
import ConfirmOverwriteModal from '../Planner/Modals/ConfirmOverwriteModal.react';
import ConfirmShiftOverwriteModal from '../Planner/Modals/ConfirmShiftOverwriteModal.react';
import LoginModal from './Modals/LoginModal.react';
import SubscriptionRequiredModal from './Modals/SubscriptionRequiredModal.react';
import EditMealServingsModal from '../Recipes/EditMealServingsModal.react';
import ChangePortionsModal from '../DailyLog/ChangePortionsModal.react';
import LogPortionsModal from './Modals/LogPortionsModal.react';
import LeftoverOffsetsModal from './Modals/LeftoverOffsetsModal.react';
import PostalCodeSelector from '../Groceries/PostalCodeSelector.react';

import GroceryActions from '../../actions/GroceryActions';
import MealActions from '../../actions/MealActions';
import PlanActions from '../../actions/PlanActions';
import UserStore from '../../stores/UserStore';
import UserActions from '../../actions/UserActions';
import MealStore from '../../stores/MealStore';
import BoardStore from '../../stores/BoardStore';
import BoardActions from '../../actions/BoardActions';
import AuthStore from '../../stores/AuthStore';
import FulfillmentProviderActions from '../../actions/FulfillmentProviderActions';

import { addDefaultAddSwapFilters, addDefaultAddSwapTags } from '../../utils/Nutrition';
import { getParticipantsForMeal, updateMealLeftovers, getParticipantsForProfileByMealType,
        isParticipating, getPrimaryMeal, isDateAvailable, getDateAvailables, getAssetsForMeals,
        getMealInfo, getServingsNeededWithLoggedServings, getLoggedServingsOfRecipe, generateDates,
        areParticipantsDifferent, getBatchInfo, getMealAndLeftovers, recalculateScaling, getCurrentActiveMealType } from '../../utils/Meals';
import { isMealInGroceries, isMealGroceryPurchased } from '../../utils/Grocery';
import { getConfig } from '../../utils/Env';

import Analytics from '../../utils/Analytics';
import { hasDeadlineExpired, isSunbasketFood } from '../../utils/Sunbasket';
import { determineScaling } from '../../utils/Recipe';

const allMealTypes = ['Breakfast', 'Snack', 'Lunch', 'Dinner'];

export default class MealActionsWrapper extends Component {
    static propTypes = {
    };

    static contextTypes = {
        confirm: PropTypes.func,
        showUpgradeForm: PropTypes.func,

        // Router stuff
        router: PropTypes.object,
        location: PropTypes.object,

        // Meal assets
        meals: PropTypes.array,
        plans: PropTypes.array,
        groceries: PropTypes.array,
        recipes: PropTypes.object,
        details: PropTypes.object,
        foods: PropTypes.object,
        locations: PropTypes.object,
        merchants: PropTypes.object,
        synced: PropTypes.bool,
        providers: PropTypes.array,

        // Scroll actions
        scrollToMeal: PropTypes.func,
    };

    static childContextTypes = {
        // Meal actions
        onModifyMeal: PropTypes.func,
        onModifyMeals: PropTypes.func,
        onRemoveMeal: PropTypes.func,
        onRemoveMeals: PropTypes.func,
        onFavoriteMeal: PropTypes.func,
        showMealDetails: PropTypes.func,
        startRescheduleMeal: PropTypes.func,
        startRepeatMeal: PropTypes.func,
        rescheduleMeals: PropTypes.func,
        shiftMeals: PropTypes.func,

        startAddMeal: PropTypes.func,
        startReplaceMeal: PropTypes.func,
        startDailyLogBarCode: PropTypes.func,
        editMealBatches: PropTypes.func,

        // Grocery actions
        syncMealsToGroceries: PropTypes.func,
        purchaseGroceryItems: PropTypes.func,
        checkSyncMealKitsErrors: PropTypes.func,
        removeMealsFromGroceries: PropTypes.func,

        // Misc actions
        profile: PropTypes.object,
        onSelectRecipe: PropTypes.func,
        onSelectCombo: PropTypes.func,
        onSelectProducts: PropTypes.func,
        onSelectFood: PropTypes.func,
        onSelectFrequentlyUsed: PropTypes.func,
        getDefaultAddSwapSettings: PropTypes.func,
        createMealFromRecipe: PropTypes.func,
        createMealsFromCombo: PropTypes.func,
        getLeftoverDateOverlaps: PropTypes.func,

        addSwapContext: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const user = UserStore.getUser();

        this.state = {
            user,

            isSubscriptionRequired: false,

            meals: [],

            postalCode: user.postal_code,
        };

        this.debouncePostalCodeSave = debounce((postalCode)=> {
            FulfillmentProviderActions.reload(postalCode);
            UserActions.updateSpecificMeta({postal_code: postalCode});
        }, 1000);
    }

    getChildContext = () => {
        const { addSwapContext, user } = this.state;

        return {
            // Meal Actions
            onModifyMeal: this.onModifyMeal,
            onModifyMeals: this.onModifyMeals,
            onRemoveMeal: this.onRemoveMeal,
            onRemoveMeals: this.onRemoveMeals,
            onFavoriteMeal: this.onFavoriteMeal,
            showMealDetails: this.showMealDetails,
            startRescheduleMeal: this.startRescheduleMeal,
            startRepeatMeal: this.startRepeatMeal,
            startDailyLogBarCode: this.startDailyLogBarCode,
            rescheduleMeals: this.rescheduleMeals,
            shiftMeals: this.shiftMeals,

            startAddMeal: this.startAddMeal,
            startReplaceMeal: this.startReplaceMeal,
            editMealBatches: this.editMealBatches,

            // Grocery actions
            syncMealsToGroceries: this.syncMealsToGroceries,
            purchaseGroceryItems: this.purchaseGroceryItems,
            checkSyncMealKitsErrors: this.checkSyncMealKitsErrors,
            removeMealsFromGroceries: this.removeMealsFromGroceries,

            // Misc actions
            profile: user,
            onSelectRecipe: this.onSelectRecipe,
            onSelectCombo: this.onSelectCombo,
            onSelectProducts: this.onSelectProducts,
            onSelectFood: this.onSelectFood,
            onSelectFrequentlyUsed: this.onSelectFrequentlyUsed,
            addSwapContext,
            getDefaultAddSwapSettings: this.getDefaultAddSwapSettings,
            createMealFromRecipe: this.createMealFromRecipe,
            createMealsFromCombo: this.createMealsFromCombo,
            getLeftoverDateOverlaps: this.getLeftoverDateOverlaps,

        };
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    onUserStoreChange = () => {
        this.setState({user: UserStore.getUser()});
    }

    checkOrderedMealChangeErrors = async (mealsToDelete) => {
        const { foods, groceries } = this.context;

        if (!mealsToDelete || mealsToDelete.length == 0) {
            return false;
        }

        const sunbasketMealsToDelete = mealsToDelete.filter(meal => isSunbasketFood(foods[meal.food_uuid]));

        if (sunbasketMealsToDelete.length == 0) {
            return false;
        }

        const orders = MealStore.getOrdersForMeals(sunbasketMealsToDelete);
        const mealsAlwaysPurchased = sunbasketMealsToDelete.filter(meal =>
            meal.orders && isMealGroceryPurchased(meal, groceries, orders));

        if (orders.length == 0 || mealsAlwaysPurchased.length == 0) {
            return false;
        }

        const firstMeal = mealsAlwaysPurchased[0];
        const firstOrder = orders[0];

        if (hasDeadlineExpired(firstMeal['date']) || (firstOrder['items'].length == 1)) {
            const message = <p>This meal has already been ordered through Sunbasket.
                             Please contact Sunbasket support to cancel the order <a href="https://sunbasket.com" target="_blank">https://sunbasket.com</a>.
                             Remove the meal from your EatLove meal feed anyway?</p>;
            return { message, acceptText: 'Yes, Remove', rejectText: 'No, Keep', isResubmittingOrder: false };
        }

        const orderDeliveryDate = moment(firstOrder['delivery_date']);
        const message = `This meal has already been ordered through Sunbasket.
                        To change your sunbasket order, resubmit your full order for
                        ${orderDeliveryDate.format('dddd, MMMM Do')} delivery.`;
        return { message, acceptText: 'Resubmit order', rejectText: 'Cancel', isResubmittingOrder: true};
    };

    modifyMeals = async (mealsToUpsert, mealsToDelete = [], leftoverDates = null) => {
        let { groceries, meals, recipes, details, foods, scrollToMeal } = this.context;
        const { user } = this.state;

        let grocerySyncMeals = [];
        let upsertLeftovers = [];
        let scrollToDate = null;
        let scrollToMealType = null;

        mealsToUpsert.forEach(meal => {
            delete meal.deleted;

            if (leftoverDates && meal.meal_type === 'fresh') {
                // Next update the leftovers.
                const { leftovers, toRemove } = updateMealLeftovers(
                    meal,
                    meals,
                    recipes[meal.recipe_uuid],
                    user,
                    leftoverDates
                );

                // Append the updated leftovers, and any leftovers that need to be removed, to our working lists.
                upsertLeftovers = upsertLeftovers.concat(leftovers);
                mealsToDelete = mealsToDelete.concat(toRemove);
            } else if (meal.meal_type === 'fresh' && !leftoverDates) {
                meals.forEach(item => {
                    if (meal.uuid === item.parent_uuid && !item.deleted) {
                        item.recipe_uuid = meal.recipe_uuid;
                        item.details_uuid = meal.details_uuid;

                        upsertLeftovers.push(item);
                    }
                });
            } else if (leftoverDates && meal.meal_type === 'food') {
                // Next update the leftovers.
                const { leftovers, toRemove } = updateMealLeftovers(
                    meal,
                    meals,
                    foods[meal.food_uuid],
                    user,
                    leftoverDates
                );

                // Append the updated leftovers, and any leftovers that need to be removed, to our working lists.
                upsertLeftovers = upsertLeftovers.concat(leftovers);
                mealsToDelete = mealsToDelete.concat(toRemove);
            }

            // If this meal is already in the groceries and needs to be synchronized, sync it.
            if ((isMealInGroceries(meal, groceries) && meal.scaling != meal.scaling_added_to_groceries)) {
                grocerySyncMeals.push(meal);
            } else if (mealsToDelete.length > 0 && meals.some(item => mealsToDelete[0].uuid === item.uuid && isMealInGroceries(item, groceries))) {
                grocerySyncMeals.push(meal);
            }

            scrollToDate = scrollToDate || meal.date;
            scrollToMealType = scrollToMealType || meal.meal;
        });

        if (mealsToDelete && mealsToDelete.length > 0) {
            // Signal to the grocery list to remove ingredients too
            GroceryActions.removeMealsFromGroceries(mealsToDelete, recipes, details, foods);

            // Collect any leftovers for these meals as well.
            const uuidsToDelete = mealsToDelete.map(m => m.uuid);
            const childrenToDelete = meals.filter(m => uuidsToDelete.includes(m.parent_uuid));
            mealsToDelete = mealsToDelete.concat(childrenToDelete);

            // Mark all meals as deleted
            mealsToDelete.forEach(m => m.deleted = true);

            // Signal the Meal Store to remove the fresh meal & the leftovers
            MealActions.deleteMeals(mealsToDelete);
        }

        if (grocerySyncMeals.length > 0) {
            GroceryActions.asyncAddMealsToGroceries(grocerySyncMeals).then(({dirtyMeals}) => {
                MealActions.upsertMeals(mealsToUpsert.concat(upsertLeftovers));
            });
        } else {
            MealActions.upsertMeals(mealsToUpsert.concat(upsertLeftovers));
        }

        scrollToDate && scrollToMealType && scrollToMeal && setTimeout(() => scrollToMeal(moment(scrollToDate), scrollToMealType), 250);
    }

    getLastRunDate = (runDay) => {

        if(!runDay) {
            return;
        }

        const today = moment();
        const targetDay = moment().day(runDay);

        while (targetDay.isAfter(today)) {
            targetDay.subtract(7, 'days');
        }

        const latestDate = targetDay.format('YYYY-MM-DD');

        this.setState({ runDate: latestDate });

        return latestDate;
    }

    onModifyMeals = async (mealsToUpsert, mealsToDelete = [], leftoverDates = null, setGroceriesPurchased = false) => {
        const { user } = this.state;
        const { synced } = this.context;

        // Do not do anything if we are not synced yet
        if (!synced) {
            return;
        }

        const { run_day, days_ahead, for_days } = user.features?.auto_populate_groceries || {};

        if (run_day && days_ahead && for_days) {
            const lastRunDate = this.getLastRunDate(run_day);
            const startDate = moment(lastRunDate).add(days_ahead, 'days');
            const endDate = moment(startDate).add(for_days - 1, 'days');

            const mealsToAdd = [], mealsToRemove = [];

            mealsToUpsert.forEach(meal => {
                const addToGroceryList = moment(meal?.date).isBetween(startDate, endDate, undefined, '[]');

                if (addToGroceryList) {
                    mealsToAdd.push(meal);
                } else {
                    mealsToRemove.push(meal);
                }
            });

            if (mealsToAdd.length) {
                await GroceryActions.asyncAddMealsToGroceries(mealsToAdd);
            }

            if (mealsToRemove.length) {
                await GroceryActions.asyncRemoveMealsFromGroceries(mealsToRemove);
            }
        }

        mealsToDelete = mealsToDelete || [];

        const error = await this.checkOrderedMealChangeErrors(mealsToDelete);

        if (!error) {
            await this.modifyMeals(mealsToUpsert, mealsToDelete, leftoverDates);
            await this.postModifySyncToGroceries(mealsToUpsert, setGroceriesPurchased, setGroceriesPurchased);
            return;
        }

        const { message, acceptText, rejectText, isResubmittingOrder } = error;
        await this.context.confirm(
            message,
            async accept => {
                await this.modifyMeals(mealsToUpsert, mealsToDelete, leftoverDates)
                this.postModifySyncToGroceries(
                    mealsToUpsert,
                    isResubmittingOrder || setGroceriesPurchased,
                    setGroceriesPurchased
                );
            },
            reject => false,
            {
                acceptText: acceptText,
                rejectText: rejectText,
            },
        );
    }

    postModifySyncToGroceries = async (mealsToUpsert, syncToGroceries = false, setToPurchased = false) => {
        if (syncToGroceries) {
            GroceryActions.asyncAddMealsToGroceries(mealsToUpsert).then((result) => {
                if (setToPurchased) {
                    this.purchaseGroceryItems(mealsToUpsert)
                }
            });
        } else if (setToPurchased) {
            this.purchaseGroceryItems(mealsToUpsert)
        }
    }

    navigateToGroceries = () => {
        const { router } = this.context;
        router.push({pathname: '/groceries'});
    }

    onModifyMeal = (mealToUpsert, mealsToDelete, leftoverDates) => {
        this.onModifyMeals([mealToUpsert], mealsToDelete, leftoverDates);
    }

    onRemoveMeals = async (mealsToDelete) => {
        const error = await this.checkOrderedMealChangeErrors(mealsToDelete);

        if (!error) {
            await this.removeMeals(mealsToDelete);
            return;
        }

        const { message, acceptText, rejectText } = error;

        await this.context.confirm(
            message,
            accept => {
                this.removeMeals(mealsToDelete);
            },
            reject => false,
            {
                acceptText: acceptText,
                rejectText: rejectText,
            },
        );
    }

    removeMeals = async (deleteMeals) => {
        let { synced, meals, recipes, details, foods } = this.context;
        const { user } = this.state;

        // If we're not synced, we can't do anything yet.
        if (!synced) {
            return;
        }

        // Find all leftovers associated with this meal, we need to remove them
        let mealsToDelete = [], plansToDelete = [];

        deleteMeals.forEach(mealToDelete => {
            mealsToDelete.push(mealToDelete);
            meals.filter(m => m.parent_uuid == mealToDelete.uuid).forEach(leftover => mealsToDelete.push(leftover));

            // Check to see how many more plan items are left, if any
            if (mealToDelete.plan_uuid) {
                // Intentionally get meals from state, because local meals variable has already been mutated
                const planMeals = this.context.meals.filter(m => {
                    return !m.deleted &&
                           m.meal_type  === 'fresh' &&
                           m.uuid       !== mealToDelete.uuid &&
                           m.plan_uuid  === mealToDelete.plan_uuid &&
                           m.plan_start === mealToDelete.plan_start
                });

                // If there are no more meals, then we should remove the meal plan too
                if (planMeals.length === 0) {
                    const plansToDelete = this.context.plans.filter(plan => {
                        return plan.plan_uuid  === mealToDelete.plan_uuid &&
                               plan.date_start === mealToDelete.plan_date;
                    });

                }
            }
        });

        if (plansToDelete.length > 0) {
            PlanActions.deletePlans(plansToDelete);
        }

        if (mealsToDelete.length > 0) {
            mealsToDelete.forEach(m => m.deleted = true);

            // Signal to the grocery list to remove ingredients too
            GroceryActions.removeMealsFromGroceries(mealsToDelete, recipes, details, foods);

            // Signal the Meal Store to remove the fresh meal & the leftovers
            MealActions.deleteMeals(mealsToDelete);
        }

        const parentMeals = meals.filter((meal) =>
            deleteMeals.find((deleteMeal) => deleteMeal.parent_uuid == meal.uuid)
            && !deleteMeals.find((deleteMeal) => deleteMeal.uuid == meal.uuid)
        );

        //When deleting leftovers directly we must update the scaling of the parent meal
        parentMeals.forEach(parentMeal => {

            const leftOversToDelete = deleteMeals.filter(deleteMeal => deleteMeal.parent_uuid == parentMeal.uuid);
            const leftOversToKeep = meals.filter(meal =>
                meal.parent_uuid == parentMeal.uuid &&
                !deleteMeals.find((deleteMeal) => deleteMeal.uuid == meal.uuid)
            );

            const scaling = this.getScaling(parentMeal);

            parentMeal.scaling = scaling;

            leftOversToKeep.forEach(leftOver => leftOver.scaling = scaling);

            this.onModifyMeals([parentMeal]);
        })

    }

    onRemoveMeal = (meal) => {
        this.onRemoveMeals([meal]);
    }

    onFavoriteMeal = (meal) => {
        const { isBoardPickerOpen } = this.state;
        const { recipes, foods } = this.context;
        const { content, recipe, food } = getPrimaryMeal([meal], recipes, foods);

        const { uuid, type } = content || {};

        if (!uuid) {
            return;
        }

        const isBoarded = BoardStore.getBoardsByResourceId(uuid).length > 0;
        const boardContent = {recipe, food};

        if (!isBoardPickerOpen && isBoarded) {
            this.setState({isBoardPickerOpen: true, boardContent});

            return;
        } else if (!isBoardPickerOpen && !isBoarded) {
            // We're going to add this item to a default board
            const lastBoard = BoardStore.getDefaultBoard();

            const newItem = {
                resource_id: uuid,
                resource_type: type,
            };

            if (lastBoard.links) {
                // Board already exists, just add to it.
                BoardActions.addToBoard(lastBoard, [newItem]);
            } else {
                lastBoard.contents = lastBoard.contents || [];
                lastBoard.contents.push(newItem);

                // Board does not exist, create a whole new one
                BoardActions.upsertBoards([lastBoard]);
            }

            Analytics.saveFavorite(lastBoard, content);

            // That's not confusing. It's the Last Board that was used,
            // but we want that one to be the First Board in the list...
            this.setState({firstBoard: lastBoard, boardContent});
        } else {
            this.setState({isBoardPickerOpen: false});
        }
    }

    syncMealsToGroceries = (meals, confirmErrors = true) => {
        const { synced } = this.context;

        if (!synced) {
            return Promise.reject();
        }

        return new Promise((resolve, rejectP) => {
            const error = this.checkSyncMealKitsErrors(meals);

            if (!error) {
                return GroceryActions.asyncAddMealsToGroceries(meals).then(resolve);
            }

            if (!confirmErrors) {
                return GroceryActions.asyncAddMealsToGroceries(meals)
                    .then(({dirtyMeals}) => this.purchaseGroceryItems(dirtyMeals).then(resolve))
                    .catch((reason) => rejectP(reason));
            }

            Analytics.openErrorDialogSunbasket({
                'Meals': meals.map(p => `${p.uuid}`),
                'Error': error
            });

            this.context.confirm(
                error,
                accept => {
                    Analytics.goBackErrorDialogSunbasket({
                        'Meals': meals.map(p => `${p.uuid}`),
                        'Error': error
                    });
                    return resolve(false);
                },
                reject => {
                    Analytics.alreadyPurchasedErrorDialogSunbasket({
                        'Meals': meals.map(p => `${p.uuid}`),
                        'Error': error
                    });
                    return GroceryActions.asyncAddMealsToGroceries(meals)
                        .then(({dirtyMeals}) => this.purchaseGroceryItems(dirtyMeals).then(resolve))
                        .catch((reason) => rejectP(reason));
                },
                {
                    acceptText: 'Go Back',
                    rejectText: 'I\’ve already purchased it',
                },
            );
        });
    }

    checkSyncMealKitsErrors = (meals, rescheduleDate = null) => {
        const { foods } = this.context;

        const mealKits = meals.filter(meal => foods[meal.food_uuid] && isSunbasketFood(foods[meal.food_uuid]));
        const allOrders = MealStore.getOrders();

        if(mealKits.length <= 0) {
            return false;
        }

        const errors = mealKits.map(meal => {
            const food = foods[meal.food_uuid];

            if (rescheduleDate) {
                return this.rescheduleMealErrors(rescheduleDate, meal, allOrders, food, false);
            }

            return this.scheduleMealErrors(meal['date'], meal, allOrders, food, false);
        });

        return errors.length > 0 ? errors[0] : false;
    }

    rescheduleMealErrors = (rescheduleDate, meal, allOrders, food, checkZipCode = false) => {
        const { groceries } = this.context;

        if (isMealGroceryPurchased(meal, groceries, allOrders)) {
            const newDate = moment(rescheduleDate);

            if (!meal['delivery_date']) {
                return false;
            }

            const deliveryDate = moment(meal['delivery_date']);

            if (newDate.isBefore(deliveryDate)) {
                return 'This meal is scheduled to be delivered on ' + deliveryDate.format('dddd, MMMM Do YYYY') + ' and can’t be rescheduled for earlier than that.';
            }

            // Verify if 5 days is enough to reschedule.
            if (newDate.isAfter(deliveryDate.clone().add(5, 'days'), 'day')) {
                return 'This meal is scheduled to be delivered on ' + deliveryDate.format('dddd, MMMM Do YYYY') + ', 5 days earlier. It may have gone bad by this point.';
            }

            return false;
        }

        return this.scheduleMealErrors(rescheduleDate, meal, allOrders, food, checkZipCode);
    }

    scheduleMealErrors = (targetDate, meal, allOrders, food, checkZipCode = false) => {
        const { groceries, providers } = this.context;
        const { user } = this.state;

        const mealDate = moment(targetDate);
        const currentDate = moment();

        if (mealDate.isBefore(currentDate, 'day')) {
            return false;
        }

        // Is this an orderable food?
        if (!isSunbasketFood(food)) { // todo later - check for other meal kit providers too
            return false;
        }

        if (isMealGroceryPurchased(meal, groceries, allOrders)) {
            return false;
        }

        // Do we have a postal code? And is the postal code deliverable?
        // For simplicity, let's assume that providers is loaded.
        // If it's not, then we should confirm the postal code anyway.
        const sunbasket = providers.find(pv => pv.provider_key === 'sunbasket' && pv.available);

        if (checkZipCode && !sunbasket) {
            return 'confirm-postal-code';
        }

        if (hasDeadlineExpired(mealDate, user?.time_zone)) {
            return 'Unfortunately you have passed the ordering deadline for this meal.';
        }

        if (isDateAvailable(mealDate, food)) {
            return false;
        }

        const dateAvailables = getDateAvailables(food);

        if (dateAvailables) {
            const nextDatesAvailable = dateAvailables.filter(p => p.isAfter(mealDate));

            if (nextDatesAvailable.length > 0) {
                const nextAvailableDate = moment(nextDatesAvailable[0]).format('dddd, MMMM Do YYYY');
                return 'We\’re sorry, the next available date Sunbasket offers this meal is ' + nextAvailableDate + '.';
            }
        }

        return 'We\’re sorry, Sunbasket is not offering this meal for delivery on this date.';
    }

    purchaseGroceryItems = (meals) => {
        const { groceries } = this.context;

        return new Promise((resolve, reject) => {
            let dirtyGroceries = [];

            meals.forEach(meal => {
                groceries.forEach(grocery => {
                    // Skip deleted items.
                    if (grocery.deleted) {
                        return;
                    }

                    if (grocery.status === 'purchased') {
                        return;
                    }

                    if (dirtyGroceries.includes(grocery)) {
                        return;
                    }


                    if ((grocery.meal_uuids || '').indexOf(meal.uuid) != -1) {
                        grocery.status = 'purchased';
                        dirtyGroceries.push(grocery);
                    }
                })
            });

            GroceryActions.upsertGroceries(dirtyGroceries);

            resolve({dirtyMeals: meals, dirtyGroceries});
        });
    };

    removeMealsFromGroceries = (meals) => {
        const { synced, recipes, details, foods } = this.context;

        // Can't do anything if the foods & details aren't loaded
        if (!synced) {
            return false;
        }

        let { dirtyMeals } = GroceryActions.removeMealsFromGroceries(meals, recipes, details, foods);

        MealActions.upsertMeals(dirtyMeals);

        return true;
    }

    showMealDetails = (multiMealDetails, dishIndex = 0) => {
        const { recipes, foods, router } = this.context;
        const { primary } = getPrimaryMeal(multiMealDetails, recipes, foods);

        if (!primary) {
            return;
        }

        router.push({pathname: `/meals/${primary.date}/${primary.meal}`, query: { 'dishIndex': dishIndex } , state: { from: router?.location?.pathname }});
    }

    trackSelectMeal = async (mealsToUpsert, mealsToDelete = null) => {
        const { recipes, foods } = await getAssetsForMeals(mealsToUpsert.concat(mealsToDelete || []));
        const { primary, recipe, food, titles } = getPrimaryMeal(mealsToUpsert, recipes, foods);
        const { organicRanking, searchRanking, resultType, searchTerm, source, sortBy, sortOrder } = this.state;

        const recipeUuids = mealsToUpsert.map(m => m.recipe_uuid).filter(v => v);
        const foodUuids = mealsToUpsert.map(m => m.food_uuid).filter(v => v);

        const mealName = mealsToUpsert[0].meal;
        const defaultMealType = getCurrentActiveMealType();
        const defaultMealIndex = ['Breakfast', 'Snack', 'Lunch', 'Dinner'].indexOf(defaultMealType);
        const date = primary.date;

        let mealTimeStatus = 'past';
        const now = moment();

        if (now.isSame(date, 'day') && mealName == defaultMealType) {
            mealTimeStatus = 'current';
        } else if (now.isSame(date, 'day') && defaultMealIndex < allMealTypes.indexOf(mealName)) {
            mealTimeStatus = 'future';
        } else if (now.isBefore(date, 'day')) {
            mealTimeStatus = 'future';
        }

        const traits = {
            'Meal UUIDs': mealsToUpsert.map(m => m.uuid),
            'Meal Types': mealsToUpsert.map(m => m.meal_type),
            "Meal Name": mealName,
            'Meal Time Status': mealTimeStatus,
            'Date': moment(primary.date).format('YYYY-MM-DD'),
            'Meal Titles': titles,

            'Search Ranking': searchRanking,
            'Result Type': resultType,
            'Search Term': searchTerm,
            'Source': source,
            'Sort By': sortBy,
            'Sort Order': sortOrder,
            'Search Type': source == "Favorites" ? 'favorites' : 'swap'
        };

        if (searchTerm?.length) {
            traits['Organic Ranking'] = organicRanking;
        }

        if (recipeUuids.length) {
            traits['Recipe UUIDs'] = recipeUuids;
        }

        if (foodUuids.length) {
            traits['Food UUIDs'] = foodUuids;
        }

        if (food?.product_type) {
            traits['Product Type'] = food.product_type;
        }

        if (food?.brand_name) {
            traits['Brand Name'] = food.brand_name;
        }

        if (food?.brand_uuid) {
            traits['Brand UUID'] = food.brand_uuid;
        }

        if (mealsToDelete?.length) {
            const deleted = getPrimaryMeal(mealsToDelete, recipes, foods);

            traits['Swapped Meal Titles'] = deleted.deletedTitles;
            traits['Swapped Meal UUIDs'] = mealsToDelete.map(m => m.uuid);
            traits['Swapped Meal Types'] = mealsToDelete.map(m => m.meal_type);

            if (deleted?.food?.product_type) {
                traits['Swapped Product Type'] = deleted.food.product_type;
            }

            if (deleted?.food?.brand_name) {
                traits['Swapped Brand Name'] = deleted.food.brand_name;
            }

            if (deleted?.food?.brand_uuid) {
                traits['Swapped Brand UUID'] = deleted.food.brand_uuid;
            }

            Analytics.swapMeal(traits);
        } else {
            Analytics.addMeal(traits);
        }
        // flush all search metadata from the previous meal
        this.setState({organicRanking: undefined, searchRanking: undefined, resultType: undefined, searchTerm: undefined, source: undefined, sortBy: undefined, sortOrder: undefined});
    }

    createMealFromRecipe = (recipe, participants, scaling = null) => {
        const { user } = this.state;
        const needed = participants.reduce((total, member) => total + member.portion, 0);

        if (!scaling) {
            scaling = Math.ceil(needed / recipe.servings);
        }

        let side_dish = ['Breakfast Side Dish', 'Lunch Side Dish', 'Side Dish'].filter(t => (recipe.tags || []).includes(t)).length > 0;

        let meal = {
            uuid: uuid.v4(),
            meal_type: 'fresh',
            logged_amount: user.portion,
            logged_unit: "serving",
            logged_grams: Math.round(recipe.grams_per_serving * 1000) / 1000 *  user.portion,
            logged_milliliters: Math.round(recipe.milliliters_per_serving * 1000) / 1000 *  user.portion,
            recipe_uuid: recipe.uuid,
            details_uuid: recipe.details,
            recipe_title: recipe.title,
            recipe_image: recipe.image,
            servings: recipe.servings,
            created: moment().format(),
            participants: participants.map(m => m.uuid).join(','),
            scaling,
            side_dish,
        };

        return meal;
    }

    createMealsFromCombo = (combo, date, mealType, participants, scaling = null) => {
        const needed = participants.reduce((total, member) => total + member.portion, 0);
        const { mainRecipe, sideRecipe } = combo;
        const { user } = this.state;

        if (!scaling) {
            // Need to figure out our main & side scaling from the participants. We use the minimum amount of food
            // required to get the main dish to match, then make the side dish last the same number of days at least.
            scaling = Math.ceil(needed / mainRecipe.servings);
        }

        const totalMainDays = Math.floor(scaling * mainRecipe.servings / needed);
        const totalSideServingsNeeded = totalMainDays * needed;
        const sideScaling = Math.ceil(totalSideServingsNeeded / sideRecipe.servings)

        let main = {
            uuid: uuid.v4(),
            servings: mainRecipe.servings,
            created: moment().format(),
            date: date.format('YYYY-MM-DD'),
            meal: mealType,
            logged_amount: user.portion,
            logged_unit: "serving",
            logged_grams: Math.round(mainRecipe.grams_per_serving * 1000) / 1000 *  user.portion,
            logged_milliliters: Math.round(mainRecipe.milliliters_per_serving * 1000) / 1000 *  user.portion,
            scaling,
            participants: participants.map(m => m.uuid).join(','),
        };

        if (mainRecipe.type == 'recipe') {
            main.meal_type = 'fresh';
            main.recipe_uuid = mainRecipe.uuid;
            main.details_uuid = mainRecipe.details;
        } else if (mainRecipe.type === 'food') {
            main.meal_type = 'food';
            main.food_uuid = mainRecipe.uuid;
        }

        let side = {
            uuid: uuid.v4(),
            servings: sideRecipe.servings,
            created: moment().format(),
            date: date.format('YYYY-MM-DD'),
            meal: mealType,
            logged_amount: user.portion,
            logged_unit: "serving",
            logged_grams: Math.round(sideRecipe.grams_per_serving * 1000) / 1000 *  user.portion,
            logged_milliliters: Math.round(sideRecipe.milliliters_per_serving * 1000) / 1000 *  user.portion,
            scaling: sideScaling,
            side_dish: true,
            participants: participants.map(m => m.uuid).join(','),
        };

        if (sideRecipe.type == 'recipe') {
            side.meal_type = 'fresh';
            side.recipe_uuid = sideRecipe.uuid;
            side.details_uuid = sideRecipe.details;
        } else if (sideRecipe.type === 'food') {
            side.meal_type = 'food';
            side.food_uuid = sideRecipe.uuid;
        }

        return {main, side};
    }

    createMealFromFood = (food, unit = null, amount = null, grams = null, milliliters = null, participants = null, autoLog = false) => {
        const { user } = this.state;
        const needed = (participants || []).reduce((total, member) => total + member.portion, 0);

        let scaling = food.servings ? Math.ceil(needed / food.servings) : 1;

        let meal = {
            uuid: uuid.v4(),
            meal_type: 'food',
            food_uuid: food.uuid,
            created: moment().format(),
            logged_unit: unit || "serving",
            logged_amount: amount || user.portion,
            logged_portion: autoLog ? 1 : null,
            logged_grams: grams,
            logged_milliliters: milliliters,
            participants: participants ? participants.map(m => m.uuid).join(',') : null,
            scaling,
        };

        return meal;
    }

    onSelectPortions = async (contents, portions, participants, autoLog = false) => {
        const { date, mealType, replaceMeals, addSwapContext, logPortionsParams } = this.state;

        // Temporary change to catch missing addSwapContext
        this.checkForMissingAddSwapContext(addSwapContext);

        const { location, scaling } = logPortionsParams || {};

        participants = participants || logPortionsParams?.participants;

        let dirtyMeals = [], uuids = [], errors = [];

        contents.forEach(content => {
            let meal = null;

            if (content.type === 'recipe') {
                meal = this.createMealFromRecipe(content, participants, scaling);
            } else if (content.type === 'food') {
                meal = this.createMealFromFood(content, null, null, null, null, participants);
            }

            meal.date = (date || addSwapContext.date).format('YYYY-MM-DD');
            meal.meal = mealType|| addSwapContext.mealType;

            if (location) {
                meal.location_uuid = location.uuid;
            }

            if (portions[content.uuid]) {
                const { logged_portion, logged_unit, logged_amount, logged_grams, logged_milliliters } = portions[content.uuid];

                meal.logged_portion = (autoLog || addSwapContext?.auto_log) ? logged_portion : null;
                meal.logged_unit = logged_unit;
                meal.logged_amount = logged_amount;
                meal.logged_grams = Math.round(logged_grams * 1000) / 1000;
                meal.logged_milliliters = Math.round(logged_milliliters * 1000) / 1000;
                meal.scaling = this.getScaling(meal, content);
            }

            const error = this.scheduleMealErrors(meal.date, meal, [], content, true);

            if (error) {
                errors.push(error);
            }

            uuids.push(content.uuid);
            dirtyMeals.push(meal);
        });

        const finishUp = (setGroceriesPurchased = false) => {
            this.onModifyMeals(dirtyMeals, replaceMeals, null, setGroceriesPurchased);
            this.closeModal();
            this.trackSelectMeal(dirtyMeals, replaceMeals);
        }

        if (!errors.length) {
            return finishUp();
        }

        // If the error is the postal code, open the capture postal code
        if (errors[0] === 'confirm-postal-code') {
            this.setState({
                isPostalCodeCaptureOpen: true,
                capturePostalCodeFor: contents,
                capturePostalCodePortions: portions,
            });

            // the capture postal code flow will funnel back into onSelectPortions,
            // which will run the validation logic again.
            return;
        }

        Analytics.openErrorDialogSunbasket({
            'Meals': dirtyMeals.map(p => `${p.uuid}`),
            'Error': errors[0]
        });

        this.context.confirm(
            errors[0],
            accept => {
                Analytics.goBackErrorDialogSunbasket({
                    'Meals': dirtyMeals.map(p => `${p.uuid}`),
                    'Error': errors[0]
                });
                return false;
            },
            reject => {
                Analytics.alreadyPurchasedErrorDialogSunbasket({
                    'Meals': dirtyMeals.map(p => `${p.uuid}`),
                    'Error': errors[0]
                });
                return finishUp(true);
            },
            {
                acceptText: "Go Back",
                rejectText: "I\’ve already purchased it",
            },
        );
    }


    getLeftoverDateOverlaps = (profile, content, scaling, participants, date, mealType, totalDays, loggedServings = null) => {
        const { meals } = this.context;
        let overlaps = [], clears = [];

        if (!profile.preferences.leftovers_enabled) {
            return {clears: [], overlaps: []}; // no leftovers enabled, disabled by the users preferences
        }

        let { tags = [], servings = 1 } = content || {};

        // Do we have a no leftovers tag? Don't bother creating leftovers records.
        if (tags.indexOf('No Leftovers') != -1) {
            return {clears: [], overlaps: []}; // no leftovers, not a good content for it.
        }

        const needed = getServingsNeededWithLoggedServings(profile, participants, loggedServings);

        if (!scaling) {
            scaling = Math.ceil(needed / servings);
        }

        const totalServings = servings * scaling;
        const calculatedLeftoverDays = Math.floor(totalServings / needed) - 1;
        const totalLeftovers = totalDays ? totalDays - 1 : calculatedLeftoverDays;

        let i = 1;
        while (i <= totalLeftovers && i <= 4) {
            const leftoverDate = moment(date).add(i++, 'day');
            const toOverwrite = meals.filter(meal => meal.meal === mealType &&
                                                     leftoverDate.isSame(meal.date, 'day'));

            if (toOverwrite.length > 0) {
                overlaps.push(leftoverDate);
            } else {
                clears.push(leftoverDate);
            }
        }

        return { clears, overlaps };
    }

    // Assumes mealsToReschedule are all in the same meal slot.
    getRescheduleOverlaps = (mealsToReschedule, date, mealType, replaceMeals = []) => {
        let overlaps = [], clears = [];
        const { user } = this.state;
        const { meals: allMeals, recipes, foods } = this.context;
        const { primary, recipe, food } = getPrimaryMeal(mealsToReschedule, recipes, foods);

        const uuidsToReschedule = mealsToReschedule.map(m => m.uuid);
        const replaceMealIds = (replaceMeals || []).map(m => m.uuid);

        // Are there meals at the destination?
        const destMeals = allMeals.filter(m => !m.deleted &&
                                            m.meal === mealType &&
                                            date.isSame(m.date, 'day') &&
                                            !uuidsToReschedule.includes(m.uuid) &&
                                            !uuidsToReschedule.includes(m.parent_uuid) &&
                                            !replaceMealIds.includes(m.uuid) &&
                                            !replaceMealIds.includes(m.parent_id));

        if (destMeals.length) {
            overlaps.push({
                mealType,
                date,
                destMeals,
                sourceMeals: mealsToReschedule,
            });
        } else {
            clears.push({
                mealType,
                date,
                sourceMeals: mealsToReschedule,
            });
        }
        const participants = getParticipantsForMeal(primary, user);
        let leftoverlaps = {leftoverLaps: [], clears: []};
        const loggedServings = getLoggedServingsOfRecipe(primary, recipe);

        if ((primary.meal_type === 'fresh' || primary.meal_type === 'leftover') && recipe) {
            leftoverlaps = this.getLeftoverDateOverlaps(user, recipe, primary.scaling, participants, date, mealType, null, loggedServings);
        } else if (primary.meal_type === 'food' && food) {
            leftoverlaps = this.getLeftoverDateOverlaps(user, food, primary.scaling, participants, date, mealType, null, loggedServings);
        }

        leftoverlaps.overlaps?.forEach(leftoverDate => {
            // Find the meals at the destination that would be overwritten.
            const destMeals = allMeals.filter(m => m.meal === mealType &&
                                                leftoverDate.isSame(m.date, 'day') &&
                                                !uuidsToReschedule.includes(m.uuid) &&
                                                !uuidsToReschedule.includes(m.parent_uuid));

            const daysDiff = leftoverDate.diff(date, 'day');
            const leftoverTrueDate = moment(primary.date).add(daysDiff, 'day');

            // we're assuming that any other dishes have been scaled to the same settings as the primary.
            let sourceMeals = allMeals.filter(m => m.meal_type === 'leftover' &&
                                                   leftoverTrueDate.isSame(m.date, 'day') &&
                                                   uuidsToReschedule.includes(m.parent_uuid));

            // If there are already meals at our destination?
            if (destMeals.length && sourceMeals.length) {
                overlaps.push({
                    date: leftoverDate,
                    mealType,
                    destMeals,
                    sourceMeals,
                });
            } else  {
                clears.push({
                    mealType,
                    date: leftoverDate,
                    sourceMeals,
                });
            }
        });

        leftoverlaps.clears?.forEach(clearDate => {
            const daysDiff = clearDate.diff(date, 'day');
            const leftoverTrueDate = moment(primary.date).add(daysDiff, 'day');

            // we're assuming that any other dishes have been scaled to the same settings as the primary.
            let sourceMeals = allMeals.filter(m => m.meal_type === 'leftover' &&
                                                   leftoverTrueDate.isSame(m.date, 'day') &&
                                                   uuidsToReschedule.includes(m.parent_uuid));

            clears.push({mealType, date: clearDate, sourceMeals});
        });

        return { mealsToReschedule, overlaps, clears }
    }

    onSelectLeftoverDates = (leftoverDates) => {
        let { replaceMeals, leftoverParams, mealType } = this.state;
        const { meals, location } = this.context;
        const { recipe, combo, participants, scaling, mainRecipe, sideRecipe, clears, meal } = leftoverParams;

        // wuut?
        if (!leftoverParams) {
            this.closeLeftoverDatesModal();
            return;
        }

        const formattedLeftoverDates = leftoverDates.map(d => d.format('YYYY-MM-DD'));

        // Find a list of meals that match mealType and leftoverOffsets
        meals.forEach(item => {
            if (!formattedLeftoverDates.includes(item.date)) {
                return;
            }

            if (item.meal === mealType) {
                replaceMeals = replaceMeals || [];
                replaceMeals.push(item);
            } else if (meal && meal.meal === item.meal) {
                replaceMeals = replaceMeals || [];
                replaceMeals.push(item);
            }
        });

        this.setState({replaceMeals}, () => {
            leftoverDates = (leftoverDates || []).concat(clears);

            if (recipe) {
                this.onSelectRecipe(recipe, participants, scaling, leftoverDates);
            } else if (combo) {
                this.onSelectCombo(combo, mainRecipe, sideRecipe, participants, scaling, leftoverDates);
            } else if (location.query.editMeal) {
                this.onChangeParticipants(participants, scaling, leftoverDates);
            }
        });
    }

    checkForMissingAddSwapContext = (addSwapContext) => {
        if (addSwapContext) {
            return;
        }

        Sentry.withScope((scope) => {
            const sentryError = new Error();
            sentryError.name = 'addSwapContext is undefined';
            Sentry.captureException(sentryError)
        });
    }

    onSelectRecipe = (recipe, participants = null, scaling = null, leftoverDates = null, searchMetadata={}) => {
        const { user, date, mealType, replaceMeals, addSwapContext, recipes, foods } = this.state;
        const { showUpgradeForm, meals } = this.context;
        const { capabilities } = user;
        let totalDays = leftoverDates ? leftoverDates.length + 1 : 1;

        let mealsToDelete = [...(replaceMeals || [])];

        // Temporary change to catch missing addSwapContext
        this.checkForMissingAddSwapContext(addSwapContext);

        participants = participants || addSwapContext.participants || [];

        if (!recipe) {
            return;
        }

        // Is this recipe protected
        if (recipe.protection !== 'public' && !capabilities.meal_planner) {
            setTimeout(() => showUpgradeForm({feature: 'meal_planner'}));
            return;
        }

        if (!leftoverDates) {
            const servings = recipe.servings || 1;
            const needed = participants.reduce((total, member) => total + member.portion, 0);
            const yeild = (scaling || 1) * servings;
            const leftovers = Math.max(Math.floor(yeild / needed) - 1, 0);

            if (leftovers) {
               leftoverDates = generateDates(date, leftovers);
                const formattedLeftoverDates = leftoverDates.map(d => d.format('YYYY-MM-DD'));

                meals.forEach(item => {
                    if (!formattedLeftoverDates.includes(item.date) || mealsToDelete.find(meal => meal.uuid == item.uuid)) {
                        return;
                    }

                    if (item.meal === mealType) {
                        mealsToDelete = mealsToDelete || [];
                        mealsToDelete.push(item);
                    }
                });
            }

            if (!leftovers) {
                const existingMealBatchInfo = getBatchInfo(date.format('YYYY-MM-DD'), mealType, user, meals);

                if (existingMealBatchInfo && !areParticipantsDifferent(participants, existingMealBatchInfo.participants)) {
                    participants = existingMealBatchInfo.participants;
                    scaling =  existingMealBatchInfo.mealServingsNeeded / recipe.servings < 1 ? 1 : Math.ceil(existingMealBatchInfo.mealServingsNeeded / recipe.servings);
                    totalDays = existingMealBatchInfo.totalDays;
                }
            }
        } else {
            const servings = recipe.servings || 1;
            const needed = participants.reduce((total, member) => total + member.portion, 0);
            const totalDays = leftoverDates.length + 1;
            const totalNeeded = needed * totalDays;
            scaling = Math.ceil(totalNeeded / servings);
        }


        this.setState({source: searchMetadata.source ?? 'Search', sortBy: searchMetadata.sortBy, sortOrder: searchMetadata.sortOrder});

        if (addSwapContext?.auto_log) {
            this.setState({
                logPortionsFor: [recipe],
                logPortionsParams: {participants, scaling},
                organicRanking: searchMetadata.organicRanking ? searchMetadata.organicRanking : undefined,
                searchRanking: searchMetadata.searchRanking ? searchMetadata.searchRanking : undefined,
                resultType: searchMetadata.resultType ? searchMetadata.resultType : undefined,
                searchTerm: searchMetadata.term
            });
            return;
        } else {
             this.setState({
                organicRanking: searchMetadata.organicRanking ? searchMetadata.organicRanking : undefined,
                searchRanking: searchMetadata.searchRanking ? searchMetadata.searchRanking : undefined,
                resultType: searchMetadata.resultType ? searchMetadata.resultType : undefined,
                searchTerm: searchMetadata.term
            });
        }

        // Are there leftovers that this recipe would overwrite?
        const { clears, overlaps } = this.getLeftoverDateOverlaps(user, recipe, scaling, participants, date, mealType, totalDays);

        if (!leftoverDates && overlaps.length > 0) {
            this.setState({
                leftoverOverlaps: overlaps,
                leftoverParams: {recipe, participants, scaling, clears}
            });

            return;
        }

        const meal = this.createMealFromRecipe(recipe, participants, scaling);
        meal.date = date.format('YYYY-MM-DD');
        meal.meal = mealType;

        this.onModifyMeals([meal], mealsToDelete, leftoverDates || clears);
        this.closeModal();
        this.trackSelectMeal([meal], mealsToDelete);
    }

    onSelectCombo = (combo, mainRecipe, sideRecipe, participants, scaling = null, leftoverDates = null, searchMetadata = {}) => {
        const { user, date, mealType, replaceMeals, addSwapContext, recipes, foods } = this.state;
        const { showUpgradeForm, meals } = this.context;
        const { capabilities } = user;
        let totalDays;

        let mealsToDelete = [...(replaceMeals || [])];

        // Temporary change to catch missing addSwapContext
        this.checkForMissingAddSwapContext(addSwapContext);

        // Are either of these recipes not allowed to be used by unsubscribed folks?
        if ((mainRecipe && mainRecipe.protection !== 'public' ||
            (sideRecipe && sideRecipe.protection !== 'public')) &&
            !capabilities.meal_planner) {
            setTimeout(() => showUpgradeForm({feature: 'meal_planner'}));
            return;
        }

        if (!leftoverDates) {
            const servings = mainRecipe.servings || 1;
            const needed = participants.reduce((total, member) => total + member.portion, 0);
            const yeild = (scaling || 1) * servings;
            const leftovers = Math.max(Math.floor(yeild / needed) - 1, 0);

            if (leftovers) {
               leftoverDates = generateDates(date, leftovers);
                const formattedLeftoverDates = leftoverDates.map(d => d.format('YYYY-MM-DD'));

                meals.forEach(item => {
                    if (!formattedLeftoverDates.includes(item.date) || mealsToDelete.find(meal => meal.uuid == item.uuid)) {
                        return;
                    }

                    if (item.meal === mealType) {
                        mealsToDelete = mealsToDelete || [];
                        mealsToDelete.push(item);
                    }
                });
            }

            if (!leftovers) {
                const existingMealBatchInfo = getBatchInfo(date.format('YYYY-MM-DD'), mealType, user, meals);

                if (existingMealBatchInfo && !areParticipantsDifferent(participants, existingMealBatchInfo.participants)) {
                    participants = existingMealBatchInfo.participants;
                    scaling = existingMealBatchInfo.mealServingsNeeded / mainRecipe.servings;
                    totalDays = existingMealBatchInfo.totalDays;
                }
            }
        } else {
            const servings = mainRecipe.servings || 1;
            const needed = participants.reduce((total, member) => total + member.portion, 0);
            const totalDays = leftoverDates.length + 1;
            const totalNeeded = needed * totalDays;
            scaling = Math.ceil(totalNeeded / servings);
        }


        this.setState({
            source: searchMetadata.source ?? 'Search',
            sortBy: searchMetadata.sortBy,
            sortOrder: searchMetadata.sortOrder,
        });

        if (addSwapContext?.auto_log) {
            this.setState({
                logPortionsFor: [mainRecipe, sideRecipe],
                logPortionsParams: {participants, scaling},
                organicRanking: searchMetadata.organicRanking ? searchMetadata.organicRanking : undefined,
                searchRanking: searchMetadata.searchRanking ? searchMetadata.searchRanking : undefined,
                resultType: searchMetadata.resultType ? searchMetadata.resultType : undefined,
                searchTerm: searchMetadata.term,
            });

            return;
        } else {
             this.setState({
                organicRanking: searchMetadata.organicRanking ? searchMetadata.organicRanking : undefined,
                searchRanking: searchMetadata.searchRanking ? searchMetadata.searchRanking : undefined,
                resultType: searchMetadata.resultType ? searchMetadata.resultType : undefined,
                searchTerm: searchMetadata.term
            });
        }

        // Are there leftovers that this combo would overwrite?
        const { clears, overlaps } = this.getLeftoverDateOverlaps(user, mainRecipe, scaling, participants, date, mealType, totalDays);
        if (!leftoverDates && overlaps.length > 0) {
            this.setState({
                leftoverOverlaps: overlaps,
                leftoverParams: {combo, mainRecipe, sideRecipe, participants, scaling, clears}
            });

            return;
        }

        combo.mainRecipe = mainRecipe;
        combo.sideRecipe = sideRecipe;
        const { main, side } = this.createMealsFromCombo(combo, date, mealType, participants, scaling);

        this.onModifyMeals([main, side], mealsToDelete, leftoverDates || clears);

        this.closeModal();
        this.trackSelectMeal([main, side], mealsToDelete);
    }

    // This function adds multiple foods and assumes a single serving of each.
    onSelectProducts = (foods, location) => {
        const { date, mealType, replaceMeals, addSwapContext } = this.state;

        // Temporary change to catch missing addSwapContext
        this.checkForMissingAddSwapContext(addSwapContext);

        if (addSwapContext?.auto_log) {
            this.setState({
                logPortionsFor: foods,
                logPortionsParams: {location},
            });

            return;
        }

        const dirtyMeals = [];

        foods.forEach(food => {
            let meal = this.createMealFromFood(food, null, null, null, null, addSwapContext?.participants);
            meal.date = date.format('YYYY-MM-DD');
            meal.meal = mealType;

            if (location) {
                meal.location_uuid = location.uuid;
            }

            dirtyMeals.push(meal);
        })

        this.onModifyMeals(dirtyMeals, replaceMeals);

        this.closeModal();
        this.trackSelectMeal(dirtyMeals, replaceMeals);
    }

    onSelectFood = async (food, unit, amount, grams, milliliters, participants = null, searchMetadata={}, autoLogOverride = false) => {
        const { user, addSwapContext } = this.state;
        const { showUpgradeForm } = this.context;
        const { capabilities } = user;

        // Temporary change to catch missing addSwapContext
        this.checkForMissingAddSwapContext(addSwapContext);

        if (!capabilities.meal_planner) {
            setTimeout(() => showUpgradeForm({feature: 'meal_planner'}));
            return;
        }

        this.setState({source: searchMetadata.source ? searchMetadata.source : (searchMetadata.term ? 'Search' : undefined), sortBy: searchMetadata.sortBy, sortOrder: searchMetadata.sortOrder});

        if ((addSwapContext?.auto_log && !autoLogOverride) || (!unit && (food.grams_per_serving || food.milliliters_per_serving))) {
            this.setState({
                logPortionsFor: [food],
                logPortionsParams: {},
                organicRanking: searchMetadata.organicRanking ? searchMetadata.organicRanking : undefined,
                searchRanking: searchMetadata.searchRanking ? searchMetadata.searchRanking : undefined,
                resultType: searchMetadata.resultType ? searchMetadata.resultType : undefined,
                searchTerm: searchMetadata.term,
            });

            return;
        } else {
             this.setState({
                organicRanking: searchMetadata.organicRanking ? searchMetadata.organicRanking : undefined,
                searchRanking: searchMetadata.searchRanking ? searchMetadata.searchRanking : undefined,
                resultType: searchMetadata.resultType ? searchMetadata.resultType : undefined,
                searchTerm: searchMetadata.term
            });
        }

        const portions = {};
        portions[food.uuid] = this.createMealFromFood(
            food, unit, amount, grams, milliliters,
            participants || addSwapContext.participants, autoLogOverride
        );

        this.onSelectPortions([food], portions, participants || addSwapContext.participants, autoLogOverride);
    }

    getDateName(date) {
        let dateName;

        if (moment().subtract(1, 'day').isSame(date, 'day')) {
            dateName = 'Yesterday';
        } else if (moment().isSame(date, 'day')) {
            dateName = 'Today';
        } else if (moment().add(1, 'day').isSame(date, 'day')) {
            dateName = 'Tomorrow';
        } else {
            dateName = moment(date).format('MMM D');
        }

        return dateName;
    }

    getDefaultAddSwapSettings = (date, mealType, participants, replaceMeals = [], options = {}) => {
        const { recipes, foods } = this.context;
        const { user } = this.state;

        // What should our modal title be?
        let verb = (replaceMeals && replaceMeals.length) ? 'Swap' : 'Add',
            dateName = this.getDateName(date);

        let modalTitle = `${verb} ${mealType}, ${dateName}`;

        const modalSettings = {
            ...options,
            allowedTypes: ['recipe', 'food', 'collection'],
            defaultTags: [],
            defaultAvoids: [],
            defaultExcludes: ['Condiment/Sauce', 'Baby Food', 'Purée', 'Foundational', 'Thanksgiving', 'Christmas', 'Supplement'],
            extraFilters: {},
            ideals: {},

            modalTitle,
        };

        if (user && user.preferences && user.preferences.leftovers_enabled) {
            let needed = participants.reduce((total, member) => total + member.portion, 0);

            modalSettings.extraFilters['sizes'] = Math.ceil(needed);
            modalSettings.extraFilters['servings'] = {lte: Math.ceil(needed * (user.preferences.max_leftover_days + 1))}
        }

        // Index the meals we're about to replace so they're easier to exclude
        const replaceUuids = replaceMeals.map(meal => meal.uuid);

        // Get all of the meals on the same day to feed into the add/swap tool.
        const meals = this.context.meals.filter(m => !m.deleted && moment(m.date).isSame(date, 'day') &&
                                                     !replaceUuids.includes(m.uuid));

        // Get a list of recipe & foods we're about to replace so we can exclude them
        const excludeUuids = replaceMeals.map(meal => meal.recipe_uuid ||
                                                      meal.food_uuid)
                                         .filter(v => v);

        const addSwapParams = {
            mealType,
            meals,
            contents: {...recipes, ...foods},
            profile: user,
            modalSettings,
            excludeUuids,
        };

        addDefaultAddSwapTags(addSwapParams);
        addDefaultAddSwapFilters(addSwapParams);

        return modalSettings;
    }

    /**
     * Proxies calls to getParticiapntsForProfileByMealType because the main profile needs
     * to always be included when manually adding meals.
     *
     * @param  {[type]} profile  [description]
     * @param  {[type]} mealType [description]
     * @return {[type]}          [description]
     */
    getParticipantsForProfileByMealType = (profile, mealType) => {
        const participants = getParticipantsForProfileByMealType(profile, mealType);

        // Is our main profile participating? If not, then we add them because we're manually adding
        // this recipe and the main profile is supposed to be default included.

        if (!isParticipating(participants, profile)) {
            participants.unshift({
                uuid: profile.uuid,
                name: profile.first_name || profile.name || profile.email,
                portion: profile.portion,
            });
        }

        return participants;
    }

    startAddMeal = (date, mealType, options = {}, doNotOpenModal = false) => {
        const { user } = this.state;

        const participants = this.getParticipantsForProfileByMealType(user, mealType);
        const modalSettings = this.getDefaultAddSwapSettings(date, mealType, participants, [], options);

        const addSwapContext = {
            mode: "add",
            participants,
            profile: user,
            mealType,
            date,
            fullBrowserSearchPlaceholder: options.auto_log ? "What did you eat?" : "What would you like to eat?",
            ...options,
        };

        if (options.auto_log) {
            addSwapContext.logged_portion = user.portion;
        }

        if (!doNotOpenModal) {

            const { router, location } = this.context;
            const { pathname, query, hash } = location;

            query.addMeal = 1;

            router.push({pathname, query, hash});
        }

        this.setState({
            date,
            mealType,
            replaceMeals: null,
            modalSettings,
            addSwapContext,
        });
    }


    startDailyLogBarCode = (date, mealType, options = {}) => {

        const addSwapContext = {
            mealType: mealType,
            date: moment(date),
            ...options,
        };

        this.setState({
            date: moment(date),
            mealType,
            addSwapContext,
        });
    }

    startReplaceMeal = (replaceMeal, options = {}) => {
        const { meals } = this.context;
        const { user } = this.state;

        const replaceMeals = options.replace_single
                           ? [replaceMeal]
                           : meals.filter(meal => meal.meal === replaceMeal.meal && meal.date === replaceMeal.date);

        const participants = replaceMeal.participants
                           ? getParticipantsForMeal(replaceMeal, user)
                           : this.getParticipantsForProfileByMealType(user, replaceMeal.meal);
        const modalSettings = this.getDefaultAddSwapSettings(replaceMeal.date, replaceMeal.meal, participants, replaceMeals, options);

        const addSwapContext = {
            mode: "replace",
            profile: user,
            participants,
            mealType: replaceMeal.meal,
            date: moment(replaceMeal.date),
            fullBrowserSearchPlaceholder: options.auto_log ? "What did you eat?" : "What would you like to eat?",
            ...options
        };

        if (options.auto_log) {
            addSwapContext.logged_portion = user.portion;
        }

        const { router, location } = this.context;
        const { pathname, query, hash } = location;

        query.swapMeal = 1;
        router.push({pathname, query, hash});

        this.setState({
            date: moment(replaceMeal.date),
            mealType: replaceMeal.meal,
            replaceMeals,
            modalSettings,
            addSwapContext,
        });
    }

    editMealBatches = (meal) => {
        const { location, meals, router } = this.context;

        // Is this a leftover? Find the main fresh meal
        const parent = meal.meal_type === 'leftover'
                     ? meals.find(m => m.uuid === meal.parent_uuid)
                     : meal;

        if (!parent) {
            return;
        }

        const { pathname, query, hash } = location;

        query.editMeal = parent.uuid;

        router.push({pathname, query, hash});
    }

    startRescheduleMeal = (mealsToReschedule) => {
        this.setState({isRescheduleMealOpen: true, mealsToReschedule});
    }

    completeRescheduleMeals = (meals, date, mealType, clears) => {
        const dateFormatted = date.format('YYYY-MM-DD');

        meals.forEach(meal => {
            meal.date = dateFormatted;
            meal.meal = mealType;
        });

        this.onModifyMeals(meals, [], clears.map(c => c.date));
        this.closeModal();
    }

    rescheduleMeals = (meals, date, mealType) => {
        const { recipes, details, foods } = this.context;
        const { mealsToReschedule, overlaps, clears } = this.getRescheduleOverlaps(meals, date, mealType);

        // There are overlaps. We need to display the confirm overwrite modal. It will
        // perform all the additional meal operations based upon the users answers.
        const error = this.checkSyncMealKitsErrors(mealsToReschedule, date);

        if(!error) {
            // If there are no overlaps, just reschedule the meal right now
            if (!overlaps.length) {
                return this.completeRescheduleMeals(meals, date, mealType, clears);
            }

            this.setState({
                isConfirmOverwriteOpen: true,
                confirmIsRepeat: false,
                confirmOverlaps: overlaps,
                confirmClears: clears,
                confirmDate: date,
                confirmMealType: mealType,
                mealsToReschedule,
            });
            return;
        }

        Analytics.openErrorDialogSunbasket({
            'Meals To Reschedule': mealsToReschedule.map(p => `${p.uuid}`),
            'Date': date,
            'Error': error
        });

        this.context.confirm(
            error,
            accept => {
                Analytics.goBackErrorDialogSunbasket({
                    'Meals To Reschedule': mealsToReschedule.map(p => `${p.uuid}`),
                    'Date': date,
                    'Error': error
                });
                return false;
            },
            reject => {
                Analytics.alreadyPurchasedErrorDialogSunbasket({
                    'Meals To Reschedule': mealsToReschedule.map(p => `${p.uuid}`),
                    'Date': date,
                    'Error': error
                });

                GroceryActions.asyncAddMealsToGroceries(mealsToReschedule)
                    .then(() => this.purchaseGroceryItems(mealsToReschedule).then());

                // If there are no overlaps, just reschedule the meal right now
                if (!overlaps.length) {
                    return this.completeRescheduleMeals(meals, date, mealType, clears);
                }

                this.setState({
                    isConfirmOverwriteOpen: true,
                    confirmIsRepeat: false,
                    confirmOverlaps: overlaps,
                    confirmClears: clears,
                    confirmDate: date,
                    confirmMealType: mealType,
                    mealsToReschedule,
                });
            },
            {
                acceptText: "Go Back",
                rejectText: "I\’ve already purchased it",
            },
        );
    }


    onSelectFrequentlyUsed = (frequentMeals, assets) => {
        const { date, mealType, replaceMeals, addSwapContext, user } = this.state;
        const { meals: allMeals } = this.context;

        this.setState({source: 'Frequently Used'});

        if (addSwapContext.auto_log) {
            this.setState({
                logPortionsFor: frequentMeals.map(item => {
                    if (item.recipe_uuid && assets.recipes[item.recipe_uuid]) return assets.recipes[item.recipe_uuid];
                    if (item.food_uuid && assets.foods[item.food_uuid]) return assets.foods[item.food_uuid];
                }),
                logPortionsParams: {defaultPortions: frequentMeals, participants: addSwapContext.participants},
            });

            return;
        }

        let meals = frequentMeals.map((item) => ({
            ...item,
            uuid: uuid.v4(),
            date: date.format('YYYY-MM-DD'),
            meal: mealType,
            logged_portion: null,
        }));

        const existingMealBatchInfo = getBatchInfo(date.format('YYYY-MM-DD'), mealType, user, allMeals);

        if (existingMealBatchInfo) {

            meals = meals.map( (meal) => {
              if(assets.recipes[meal.recipe_uuid]) {
                  meal.participants = existingMealBatchInfo.participants.map(m => m.uuid).join(',');
                  meal.scaling = existingMealBatchInfo.mealServingsNeeded / assets.recipes[meal.recipe_uuid].servings;
              }
              return meal;
            });
        }

        const { mealsToReschedule, overlaps, clears } = this.getRescheduleOverlaps(meals, date, mealType, replaceMeals);



        if (overlaps.length > 0 && (!replaceMeals || replaceMeals.length == 0)) {
            // Pass the meals off to the conflict resolver which will also handle saving them.
            this.setState({
                isConfirmOverwriteOpen: true,
                confirmIsRepeat: true,
                confirmOverlaps: overlaps,
                confirmClears: clears,
                confirmDate: date,
                confirmMealType: mealType,
                mealsToReschedule,
            });
            return;
        }

        this.onModifyMeals(mealsToReschedule, replaceMeals, clears.map(c => c.date));

        let uuids = meals.map(({recipe_uuid, food_uuid}) => recipe_uuid || food_uuid);

        this.closeModal();
        this.trackSelectMeal(mealsToReschedule, replaceMeals);
    }

    shiftMeals = (startDate, daysToShift) => {
        const { meals } = this.context;

        const mealsToShift = meals.filter(meal => startDate.isSameOrBefore(meal.date, 'day'));
        // Are there any meals between startDate-daysToShift and startDate? If so, we need to throw up the
        // shift conflict resolve modal
        if (daysToShift < 0) {
            const pastDate = moment(startDate).add(daysToShift, 'day');
            const mealConflicts = meals.filter(meal => startDate.isAfter(meal.date, 'day') &&
                                                       pastDate.isSameOrBefore(meal.date, 'day'));

            if (mealConflicts.length > 0) {
                // conflict! We need to confirm the shift.

                this.setState({isConfirmShiftOverwriteOpen: true, confirmStartDate: startDate, confirmDaysToShift: daysToShift});
                return;
            }
        }

        mealsToShift.forEach(meal => {
            meal.date = moment(meal.date).add(daysToShift, 'day').format('YYYY-MM-DD');

            delete meal.logged_portion;
        });

        MealActions.upsertMeals(mealsToShift);
        this.closeModal();
    }

    startRepeatMeal = (mealsToRepeat) => {
        this.setState({isRepeatMealOpen: true, mealsToRepeat});
    }

    onChangeParticipants = (participants, scaling, leftoverDates = null, totalDays = null) => {
        const { location, meals, recipes, foods } = this.context;
        const { user, replaceMeals } = this.state;

        if (!location.query.editMeal) {
            return null;
        }

        const meal = meals.find(meal => meal.uuid === location.query.editMeal);

        const mealDishes = meals.filter(m => m.meal === meal.meal && m.date === meal.date);


        if (leftoverDates) {
            mealDishes.forEach((dish, index) => {
              mealDishes[index].scaling = recalculateScaling(dish, participants, recipes[dish.recipe_uuid], leftoverDates.length + 1, user, meals);
            });
        }

        let clears = [], overlaps = [];

        // Are there leftovers that this recipe would overwrite?
        const { content } = getPrimaryMeal([meal], recipes, foods);
        const loggedServings = getLoggedServingsOfRecipe(meal, content);

        if (['recipe', 'food'].includes(content.type)) {
            ({ clears, overlaps } = this.getLeftoverDateOverlaps(
                user, content, typeof scaling === 'object' ? scaling[location.query.editMeal] : scaling, participants, meal.date, meal.meal, totalDays, loggedServings
            ));


            if (!leftoverDates && overlaps.length > 0) {
                this.setState({
                    leftoverOverlaps: overlaps,
                    leftoverParams: {participants, scaling, clears, meal: meal}
                });

                return;
            }
        }

        let dirtyMeals = [];

        mealDishes.forEach(dish => {
            if (dish.recipe_uuid || (dish.food_uuid && foods[dish.food_uuid]?.servings)) {
                if (!leftoverDates){
                    dish.scaling = typeof scaling === 'object' ? scaling[dish.uuid] : scaling;
                }
                dish.leftovers_removed = dish.scaling * (recipes[dish.recipe_uuid]?.servings || foods[dish.food_uuid]?.servings) - dish.logged_amount;
                dish.participants = participants.map(p => p.uuid || '').join(',');
                dirtyMeals.push(dish);
            }
        })

        this.onModifyMeals(dirtyMeals, replaceMeals, leftoverDates || clears);

        this.closeEditMealServingsModal();
        this.closeLeftoverDatesModal();
    }

    closeEditMealServingsModal = () => {
        const { router, location } = this.context;
        const { pathname, query, hash } = location;

        delete query.editMeal;

        router.push({pathname, query, hash});
    }

    closeLogPortionsModal = () => {
        this.setState({logPortionsFor: null, logPortionsParams: null});
    }

    closeLeftoverDatesModal = () => {
        this.setState({leftoverOverlaps: null, leftoverClears: null, leftoverParams: null});
    }

    closeConfirmOverwriteModal = (closeAll = true) => {
        if (closeAll === true) {
            return this.closeModal();
        }

        this.setState({
            isConfirmOverwriteOpen: false,
            confirmIsRepeat: false,
            confirmOverlaps: null,
            confirmClears: null,
            confirmStartDate: null,
            confirmDaysToShift: null,
        });
    }

    closeModal = () => {
        const { router, location } = this.context;
        const { pathname, query, hash } = location;

        delete query.mid;
        delete query.addMeal;
        delete query.swapMeal;
        delete query.editMeal;

        router.push({pathname, query, hash});

        this.setState({
            isRescheduleMealOpen: false,
            isRepeatMealOpen: false,
            isConfirmOverwriteOpen: false,
            isConfirmShiftOverwriteOpen: false,
            isSubscriptionRequired: false,
            isBoardPickerOpen: false,
            isPostalCodeCaptureOpen: false,
            boardContent: null,
            modalSettings: null,
            showMealsDetail: null,
            multiMealDetails: null,
            mealsToReschedule: null,
            mealsToRepeat: null,
            confirmIsRepeat: false,
            confirmOverlaps: null,
            confirmClears: null,
            confirmStartDate: null,
            confirmDaysToShift: null,
            replaceMeals: null,
            addSwapContext: null,
            logPortionsFor: null,
            logPortionsParams: null,
            leftoverOverlaps: null,
            leftoverClears: null,
            leftoverParams: null,
            capturePostalCodeFor: null,
            capturePostalCodePortions: null,
            capturePostalCodeWorking: null,
            capturePostalCodeAlert: null,
        });

        $(window).scrollTop(0);
    }

    renderRescheduleMealModal = () => {
        const { isRescheduleMealOpen, mealsToReschedule } = this.state;

        if (!isRescheduleMealOpen || !mealsToReschedule) {
            return;
        }

        return <MealRescheduleModal meals={mealsToReschedule}
                    onModifyMeals={this.onModifyMeals}
                    rescheduleMeals={this.rescheduleMeals}
                    shiftMeals={this.shiftMeals}
                    closeModal={this.closeModal} />
    }

    renderRepeatMealModal = () => {
        const { isRepeatMealOpen, mealsToRepeat } = this.state;

        if (!isRepeatMealOpen && !mealsToRepeat) {
            return;
        }

        return <MealRepeatModal meals={mealsToRepeat}
                    getLeftoverDateOverlaps={this.getLeftoverDateOverlaps}
                    onModifyMeals={this.onModifyMeals}
                    closeModal={this.closeModal} />
    }

    renderConfirmOverwriteModal = () => {
        const { isConfirmOverwriteOpen, mealsToReschedule, confirmOverlaps, confirmClears, confirmDate, confirmMealType, confirmIsRepeat } = this.state;

        if (!isConfirmOverwriteOpen || !mealsToReschedule) {
            return null;
        }

        return <ConfirmOverwriteModal meals={mealsToReschedule}
                    onModifyMeals={this.onModifyMeals}
                    overlaps={confirmOverlaps}
                    isRepeatMeal={confirmIsRepeat}
                    clears={confirmClears}
                    date={confirmDate}
                    mealType={confirmMealType}
                    closeModal={this.closeConfirmOverwriteModal} />
    }

    renderConfirmShiftOverwriteModal = () => {
        const { isConfirmShiftOverwriteOpen, confirmStartDate, confirmDaysToShift } = this.state;
        const { meals } = this.context;

        if (!isConfirmShiftOverwriteOpen) {
            return null;
        }

        return <ConfirmShiftOverwriteModal startDate={confirmStartDate}
                    meals={meals}
                    daysToShift={confirmDaysToShift}
                    onModifyMeals={this.onModifyMeals}
                    closeModal={this.closeModal} />
    }

    renderLoginModal = () => {
        const { user } = this.state;

        if (user) {
            return null;
        }

        return (
            <LoginModal closeModal={() => this.context.router.push('/')} />
        );
    }

    renderSubscriptionRequiredModal = () => {
        const { isSubscriptionRequired } = this.state;

        if (!isSubscriptionRequired) {
            return null;
        }

        return (
            <SubscriptionRequiredModal closeModal={this.closeModal} />
        );
    }

    renderAddSwapRecipeModal = () => {
        const { location } = this.context;
        const { user, modalSettings, addSwapContext } = this.state;

        if (!modalSettings || !(location.query.addMeal || location.query.swapMeal)) {
            return;
        }

        // Leftovers are not editable when logging
        const editableLeftovers = (addSwapContext.auto_log) ? false : true;

        return (
            <AddSwapRecipe profile={user}
                closeModal={this.closeModal}
                onSelectRecipe={this.onSelectRecipe}
                onSelectCombo={this.onSelectCombo}
                onSelectFood={this.onSelectFood}
                editableLeftovers={editableLeftovers}
                startAddMeal={this.startAddMeal}
                {...modalSettings} />
        );
    }

    renderEditMealServingsModal = () => {
        const { location, meals, recipes, foods } = this.context;
        const { user } = this.state;

        if (!location.query.editMeal || !recipes) {
            return null;
        }

        let contents = {};

        // Find the meal
        const meal = meals.find(m => m.uuid === location.query.editMeal);

        if (!meal) {
            return null;
        }

        const mealDishes = meals.filter(m => m?.meal === meal.meal && m?.date === meal.date && !m?.deleted && (recipes[m?.recipe_uuid]?.servings || foods[m?.food_uuid]?.servings));

        mealDishes.forEach((dish) => {
            if(recipes[dish.recipe_uuid]?.servings) {
                contents[dish.recipe_uuid] = recipes[dish.recipe_uuid]
            }

            if(foods[dish.food_uuid]?.servings) {
                contents[dish.food_uuid] = foods[dish.food_uuid]
            }
        });

        const participants = getParticipantsForMeal(meal, user);

        if (!(user && participants && Object.keys(contents).length)) {
            return null;
        }

        // Renders the edit participant modal directly right here.
        return (
            <EditMealServingsModal
                closeModal={this.closeEditMealServingsModal}
                contents={contents}
                mealDishes={mealDishes}
                profile={user}
                participants={participants}
                onChangeParticipants={this.onChangeParticipants} />
        );
    }

    getScaling = (meal, content = null) => {
        const { meals, recipes, foods } = this.context;
        const { user } = this.state;

        let allMeals = getMealAndLeftovers(meal, meals);

        // getScaling should never be called for content that does not have servings aka non-sunbasket foods
        if (content || meal.recipe_uuid || meal.food_uuid) {
            let recipe = content || recipes[meal.recipe_uuid] || foods[meal.food_uuid];
            const participants = getParticipantsForMeal(meal, user);
            const totalDays = allMeals.length;
            const loggedServings = getLoggedServingsOfRecipe(meal, recipe);
            const primaryUserMealServingsNeeded = allMeals.reduce((sum, meal) => sum + loggedServings, 0);
            const otherUsersMealServingsNeededPerDay = participants.reduce((sum, participant) => sum + (participant.uuid == user.uuid ? 0 : participant.portion), 0);
            const mealServingsNeeded = (otherUsersMealServingsNeededPerDay * totalDays) + primaryUserMealServingsNeeded;
            return Math.ceil(mealServingsNeeded / (recipe.servings || 1));
        }

        return meal.scaling || 1;
    }

    onChangeAmount = (portion, unit, amount, grams, milliliters) => {
        const { location, meals } = this.context;

        if (!location.query.editPortionsMealUuid) {
            return null;
        }

        const meal = meals.find(m => m.uuid === location.query.editPortionsMealUuid);

        let allMeals = getMealAndLeftovers(meal, meals);

        allMeals.forEach(meal => {
            if (meal.uuid === location.query.editPortionsMealUuid) {
                meal.logged_portion = portion;
                meal.logged_amount = amount;
                meal.logged_unit = unit;
                meal.logged_grams = grams;
                meal.logged_milliliters = milliliters;
                meal.scaling = this.getScaling(meal);
            }
        })

        this.onModifyMeals(allMeals);
    }


    closeChangePortionsModal = () => {
        const { router, location } = this.context;
        const { pathname, query, hash } = location;

        delete query.editPortionsMealUuid;

        router.push({pathname, query, hash});
    }


    renderChangePortionsModal = () => {
        const { location, meals, recipes, foods } = this.context;

        if (!location.query.editPortionsMealUuid) {
            return null;
        }

        const meal = meals.find(m => m.uuid === location.query.editPortionsMealUuid);

        if (!meal) {
            return null;
        }

        let food;

        if (meal.recipe_uuid) {
            food = recipes[meal.recipe_uuid];
        }

        if (meal.food_uuid) {
            food = foods[meal.food_uuid];
        }

        if (!food) {
            return null;
        }

        return (
            <ChangePortionsModal
                howMuchQuestion={"How much?"}
                meal={meal}
                food={food}
                onChangeAmount={this.onChangeAmount}
                closeModal={this.closeChangePortionsModal} />
        );
    }

    renderBoardPickerModal = () => {
        const { isBoardPickerOpen, firstBoard, boardContent } = this.state;

        if (!isBoardPickerOpen) {
            return null;
        }

        return <BoardPickerModal closeModal={this.closeModal} {...boardContent} firstBoard={firstBoard} />
    }

    renderLogPortionsModal = () => {
        const { user, logPortionsFor, logPortionsParams, addSwapContext } = this.state;

        if (!logPortionsFor) {
            return null;
        }

        let modalTitle = addSwapContext?.auto_log ? 'How much did you eat?' : 'How much?';
        let ctaText    = addSwapContext?.auto_log ? 'Log Food' : 'Save';

        if (addSwapContext?.auto_log && logPortionsFor.length == 1 && (logPortionsFor[0].tags || []).includes('Beverage')) {
            modalTitle = 'How much did you drink?';
        }

        return <LogPortionsModal closeModal={this.closeLogPortionsModal}
            profile={user} requirePrecise={false}
            modalTitle={modalTitle}
            ctaText={ctaText}
            contents={logPortionsFor}
            defaultPortions={logPortionsParams.defaultPortions}
            onSelectPortions={this.onSelectPortions} />
    }

    /**
     * This modal is for asking the user if they want to overwrite any future planned meals with leftovers
     * that would be created from the action they're about to take.
     *
     * @return {[type]} [description]
     */
    renderLeftoverDatesModal = () => {
        const { leftoverOverlaps, leftoverParams } = this.state;

        if (!leftoverOverlaps) {
            return null;
        }

        return <LeftoverOffsetsModal offsets={leftoverOverlaps}
            closeModal={this.closeLeftoverDatesModal}
            params={leftoverParams}
            onSelectLeftoverOffsets={this.onSelectLeftoverDates} />
    }

    onChangePostalCode = (ev) => {
        const postalCode = ev.target.value;
        this.setState({postalCode});
        this.debouncePostalCodeSave(postalCode);
    }

    onClosePostalCaptureModal = () => {
        this.setState({
            isPostalCodeCaptureOpen: false,
            capturePostalCodeFor: null,
            capturePostalCodePortions: null,
            capturePostalCodeWorking: null,
            capturePostalCodeAlert: null,
        });
    }

    onSavePostalCode = async () => {
        const { postalCode, capturePostalCodeFor, capturePostalCodePortions } = this.state;
        const { providers } = this.props;

        if (!postalCode) {
            this.setState({capturePostalCodeAlert: 'Please enter your postal code'});
            return;
        }

        this.setState({capturePostalCodeWorking: true});

        const response = await AuthStore.fetch({url: getConfig('recipe_api') + '/providers?postal_code=' + (postalCode || '')});

        // @todo - in theory, we'll compare capturePostalCodeFor content to the provider key. But we don't
        // need that for this release.
        const sunbasket = response?.elements.find(pv => pv.provider_key === 'sunbasket' && pv.available);

        if (!sunbasket) {
            this.setState({
                capturePostalCodeWorking: false,
                capturePostalCodeAlert: 'Very sorry but Sunbasket delivery is not available for your postal code.'
            });

            return;
        }

        this.onSelectPortions(capturePostalCodeFor, capturePostalCodePortions);
    }

    renderPostalCodeCaptureModal = () => {
        const { postalCode, isPostalCodeCaptureOpen, capturePostalCodeWorking, capturePostalCodeAlert } = this.state;

        if (!isPostalCodeCaptureOpen) {
            return;
        }

        return (
            <PostalCodeSelector isModalOpen
                postalCode={postalCode}
                working={capturePostalCodeWorking}
                closeModal={this.onClosePostalCaptureModal}
                onSavePostalCode={this.onSavePostalCode}
                alertMsg={capturePostalCodeAlert}
                modalTitle="Confirm Delivery Postal Code"
                onChangePostalCode={this.onChangePostalCode}>

                <p className="p3">Please confirm your delivery postal code to select this meal</p>

            </PostalCodeSelector>
        );
    }

    render() {
        return (
            <div>
                {this.props.children}
                {this.renderRescheduleMealModal()}
                {this.renderRepeatMealModal()}
                {this.renderLoginModal()}
                {this.renderSubscriptionRequiredModal()}
                {this.renderAddSwapRecipeModal()}
                {this.renderEditMealServingsModal()}
                {this.renderBoardPickerModal()}
                {this.renderLogPortionsModal()}
                {this.renderLeftoverDatesModal()}
                {this.renderConfirmOverwriteModal()}
                {this.renderConfirmShiftOverwriteModal()}
                {this.renderPostalCodeCaptureModal()}
                {this.renderChangePortionsModal()}
            </div>
        );
    }
}
